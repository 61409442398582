import { BigIntString } from "@smartrr/shared/entities/BigIntString";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import {
  IOrderCSV,
  ISmartrrFulfillmentStatus,
  ISmartrrOrderWithCustomerRelationship,
} from "@smartrr/shared/entities/Order";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";

import { getOrders } from "@vendor-app/app/_state/actionCreators/order";

import { getQueryObj } from "./getQueryObject";
import { mapArrayEntityWithDelay } from "./mapArrayEntityWithDelay";

export interface IExportCSVFilters {
  startDate?: string;
  endDate?: string;
  filterIn?: { [key: string]: string[] };
  filterLike?: { [key: string]: string };
  orderByField?: string;
}

export async function exportOrdersToCsvRows(filters?: IExportCSVFilters, activeOrg?: IOrganization | null) {
  const startDate = filters?.startDate;
  const endDate = filters?.endDate;

  const orderByField = filters?.orderByField ?? "shopifyOrderLabel";

  const filterIn = filters?.filterIn;
  const filterLike = filters?.filterLike;

  const allData: ISmartrrOrderWithCustomerRelationship[] = [];
  let pageNumber = 0;
  let totalPages = 1;

  while (pageNumber < totalPages) {
    const page = await getOrders(getQueryObj(pageNumber, startDate, endDate, filterIn, filterLike, orderByField));

    if (page.type === "error") {
      throw new Error("Error fetching orders");
    }

    allData.push(...page.body.data);
    pageNumber = page.body.pageNumber;
    totalPages = page.body.totalPages;
    pageNumber++;
  }

  return await mapArrayEntityWithDelay<ISmartrrOrderWithCustomerRelationship, IOrderCSV>({
    items: allData,
    chunkSize: 250,
    waitInterval: 500,
    filterFunction: item => parseOrderForCSV(item, activeOrg),
  });
}

const getOrderStatus = (fulfillmentStatus: ISmartrrFulfillmentStatus | `${ISmartrrFulfillmentStatus}`) => {
  let text = "";
  switch (fulfillmentStatus) {
    case "fulfilled": {
      text = "Fulfilled";
      break;
    }
    case "partial": {
      text = "Partially fulfilled";
      break;
    }
    case "unshipped": {
      text = "Unfulfilled";
      break;
    }
    default: {
      text = "Unfulfilled";
    }
  }

  return text;
};

export function parseOrderForCSV(
  order: ISmartrrOrderWithCustomerRelationship,
  activeOrg?: IOrganization | null
): IOrderCSV {
  let total;
  let totalInCustomerCurrency;
  const processedDate = ISODateString.fromString(order.orderProcessedDate);
  const orgCurrency = activeOrg?.shopifyStoreData?.currency ?? "USD";

  const processedDateStr = `${processedDate.toLocaleString({
    day: "2-digit",
    month: "short",
    year: "numeric",
  })} at ${processedDate.toLocaleString({
    hour: "numeric",
    hour12: true,
  })}`;

  if (
    !isNaN(order.shopIncome) &&
    !isNaN(order.totalNet) &&
    !isNaN(order.totalRefund) &&
    (order.orderStatus !== "cancelled" || (order.orderStatus == "cancelled" && order.totalRefund == 0))
  ) {
    total = formatMoney(BigIntString.fromBigIntString(order.shopIncome - order.totalRefund), orgCurrency);
    totalInCustomerCurrency = formatMoney(BigIntString.fromBigIntString(order.totalNet), order.orderCurrency);
  } else {
    total = formatMoney(BigIntString.fromBigIntString(0), orgCurrency);
    totalInCustomerCurrency = formatMoney(BigIntString.fromBigIntString(0), order.orderCurrency);
  }

  return {
    Order: order.shopifyOrderLabel,
    Date: processedDateStr,
    "Fulfillment status": getOrderStatus(order.fulfillmentStatus),
    Customer: `${order.custRel?.firstName} ${order.custRel?.lastName}`,
    Items: order.items.length,
    "Total before tax": total,
    "Cust. currency": totalInCustomerCurrency,
    "Smartrr Order ID": viewShopifyId(order.shopifyId),
  };
}
